<template>
<div>

    <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

    <div class="row">
        <div class="col-md-12">
            <CCard>
                <CCardBody>
                    <CForm novalidate>
                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Type of user
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    required
                                    was-validated
                                    v-model="data.category"
                                />
                                <template v-if="!data.category && buttonClick== true">
                                    <p class="errorMessage">User type required</p>
                                </template>
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Graph
                            </label>

                            <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CRow form class="form-group mt-2">
                                        <CCol sm="12">
                                            <CInputCheckbox
                                            v-for="(option, optionIndex) in sensorList"
                                            :key="optionIndex"
                                            :label="option"
                                            class="mt-2"
                                            :value.sync="option.indexOf[0]"
                                            :inline="true"
                                            @change="updateDashBoardData(option)"
                                            />
                                        </CCol>
                                    </CRow>
                            </div>
                        </div>

                       
                    </CForm>

                    <div class="form-group form-actions mr-3 float-right">
                        <router-link to="/master">
                            <CButton type="submit" size="sm" color="danger">
                                Cancel
                            </CButton>
                        </router-link>
                        <CButton
                            type="submit"
                            size="sm"
                            color="primary"
                            class="ml-3"
                            @click="errorCheck()"
                        >
                            Save
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const companyCategoryUrl = URL + `${"profile/bank/bashboard/settings/"}`;
const sensorListUrl = URL + `${"profile/sensor/type/"}`;

export default {
    name: "NewTypeUser",
    // components: { CTableWrapper },
    data() {
        return {
            buttonClick: false,
            data: {
                category: "",
                dashboard_data: [],
            },
            sensorList: [],
            errorMessage: "",
            checkError: false,
        };
    },

    methods: {
        errorCheck(){
            this.buttonClick= true;

            if(this.data.category.trim().length!= 0){
                    this.buttonClick= false;
                    this.createCompanyCategory();
               }
        },

        updateDashBoardData(value) {
          this.data.dashboard_data.push(value);
        },
        createCompanyCategory() {
            this.axios
                .post(companyCategoryUrl, this.data, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    this.$router.push("/master");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
        getSensorList() {
            this.axios
                .get(sensorListUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.sensorList = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
    
    beforeMount() {
        this.getSensorList();
    },
};
</script>